// You can add your own mixins here

@mixin tablet() {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin laptop() {
  @media screen and (min-width: $laptop) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

// Can use drop-shadow here
@mixin shadow() {
  @supports not (-ms-high-contrast: none) {
    @content;
  }
}

@mixin flex($way, $vposition, $hposition) {
  display: flex;
  flex-direction: $way;
  justify-content: $vposition;
  align-items: $hposition;
}