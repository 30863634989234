@import "src/static/sass/basic/common";

.container {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 280px;
  max-width: 100%;

  @media (min-width: $tablet) {
    width: 728px;
  }

  @media (min-width: $laptop) {
    max-width: 1180px;
  }
}

// normalize-scss
@import "node_modules/normalize-scss/sass/_normalize";
@include normalize();

// Шрифты
@import "src/static/sass/basic/_fonts";
@import "src/static/sass/basic/_variables";
@import "src/static/sass/basic/_mixins";
@import "src/static/sass/basic/_global";
@import "src/static/sass/basic/_ui";
/* Your custom fonts here */

/* Example

@font-face {
    font-family: 'CustomFont';
    src: url('fonts/Custom-Font.woff2') format('woff2'),
         url('fonts/Custom-Font.woff') format('woff');
    font-weight: 400;       // For normal width. It could has another value   
    font-style: normal;     // Also could has another value
    }



*/

// Var for using custom font
$CustomFont: 'Open Sans',
Helvetica,
Arial,
sans-serif;

// Border-box на html и все остальные элементы наследуют свойство.
// Позволяет добавить элемент с другим box-sizing.
html {
  height: 100%; // min-height: 100%;
  box-sizing: border-box;
  font-size: 10px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $basicFontFamily;
  font-size: $basicFontSize; // Базовый размер шрифта
  line-height: $basicLineHeight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page__wrapper {
  // height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  min-width: $mobile;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}
// You can add your own mixins here

@mixin tablet() {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin laptop() {
  @media screen and (min-width: $laptop) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

// Can use drop-shadow here
@mixin shadow() {
  @supports not (-ms-high-contrast: none) {
    @content;
  }
}

@mixin flex($way, $vposition, $hposition) {
  display: flex;
  flex-direction: $way;
  justify-content: $vposition;
  align-items: $hposition;
}
// GUI styles of the project
// For example, styles of the links, buttons and so on.
a {
  font: inherit;
  color: inherit;
  border: none;
  outline: none;
  transition: 0.3s;
  cursor: default;
  text-decoration: none;
  transition-property: background-color;

  @include laptop() {
    cursor: pointer;
  }
}

button {
  font: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  cursor: default;
  background: none;
  transition: 0.3s;
  transition-property: background-color;

  @include laptop() {
    cursor: pointer;
  }
}

// Стили для чекбоксов и радиокнопок
// Для работы кладем i после кнопки внутрь label
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]+i {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 21px;
  height: 20px;
  background-color: $firstColor;
}

input[type="checkbox"]:checked+i::after {
  content: "";
  display: block;
  width: 9px;
  height: 6px;
  position: absolute;
  top: 6px;
  left: 6px;
  border-left: 2px solid $white;
  border-bottom: 2px solid $white;
  transform: rotate(-45deg);
}

input[type="radio"] {
  display: none;
}

input[type="radio"]+i {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 21px;
  height: 21px;
  background-color: $white;
  border-radius: 50%;
}

input[type="radio"]:checked+i::after {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 6px;
  left: 6px;
  background-color: $firstColor;
  border-radius: 50%;
}
// File for scss-vars.
// For example: main colors, paddings and so on.
// Basic <body> styles 
$basicFontFamily: $CustomFont; // Берем нужный шрифт из переменной в fonts.scss
$basicFontSize: 14px; // Базовый размер шрифта
$basicLineHeight: 1.2;
// Screen sizes
$mobile: 320px;
$iphone: 375px;
$tablet: 768px;
$laptop: 1240px;
$desktop: 1920px;
// Colors
$black: #000;
$white: #fff;
$firstColor: #000;
$secondColor: #000;
$thirdColor: #000;

//@import "../../styles/mixins";

._reactTemplate {

}
@mixin bgi($image, $format) {
  background-image: url('img/welcome/' + $image + '.' + $format);

  @supports (background-image: url('test.webp')) {
    background-image: url('img/welcome/' + $image + '.webp');
  }
}
// @include bgi('background', 'png');


.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #AFCA0B;
  height: 100vh;
  min-height: 700px;
  padding: 20px;
  background-image: url('img/welcome/bg-m.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  font-family: "Cuprum", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  @include tablet() {
    min-height: 510px;
    background-image: url('img/welcome/bg-t.svg');
  }

  @include laptop() {
    background-image: url('img/welcome/bg-d.svg');
  }

  @media screen and (min-width: 2400px) {
    background-image: url('img/welcome/bg-xd.svg');
  }
}

.welcome__logo {
  display: block;
  width: 155px;
  position: absolute;
  top: 40px;
  left: 20px;

  @include tablet() {
    width: 178px;
    top: 27px;
    left: 60px;
  }

  @include laptop() {
    width: 266px;
    top: 40px;
    left: 60px;
  }
}

.welcome__img {
  display: block;
}

.welcome__title {
  display: block;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 35px;
  line-height: 85%;
  text-align: center;
  color: #e42313;

  @include tablet() {
    font-weight: 700;
    font-size: 45px;
    line-height: 85%;
    text-align: center;
  }

  @include laptop() {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 65px;
    line-height: 85%;
    text-align: center;
  }

  @include desktop() {

    font-weight: 700;
    font-size: 80px;
    line-height: 85%;
    text-align: center;
  }

  @media screen and (min-width: 2400px) {
    margin-bottom: 40px;
  }
}

.welcome__descr {
  display: block;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  text-align: center;
  color: #fff;

  a {
    text-decoration: underline;
    text-decoration-skip-ink: none;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 20px;
  }

  @include tablet() {

  }

  @include laptop() {
    font-weight: 400;
    font-size: 25px;
    line-height: 130%;
    text-align: center;

    p {
      margin-bottom: 30px;
    }
  }

  @include desktop() {
    font-weight: 400;
    font-size: 30px;
    line-height: 130%;
    text-align: center;
  }
}

@mixin bgi($image, $format) {
  background-image: url('img/_template/' + $image + '.' + $format);

  @supports (background-image: url('test.webp')) {
    background-image: url('img/_template/' + $image + '.webp');
  }
}
// @include bgi('background', 'png');


._template {
  display: block;

  @include tablet() {

  }

  @include laptop() {

  }

  @include desktop() {

  }
}